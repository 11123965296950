.loading-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    transform: translateY(-30px);
}

.loading-killua {
    padding: 40px;
    height: 200px;
}


@media (max-width: 768px) {
    .loading-container {
        font-size: 28px;
    }
}