@font-face {
    font-family: 'RobotoSerif';
    src: url("/src/assets/fonts/Roboto_Slab/RobotoSlab-VariableFont_wght.ttf") format('truetype');
}

@font-face {
    font-family: 'Roboto';
    src: url('/src/assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'Roboto';
    src: url('/src/assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Roboto';
    src: url('/src/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
}
  
@font-face {
    font-family: 'Roboto';
    src: url('/src/assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
}
  
@font-face {
    font-family: 'Roboto';
    src: url('/src/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto';
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

h1, h2 {
    font-family: "RobotoSerif";
    font-weight: 400;
}

h3, h4, h5, h6 {
    font-family: 'Roboto';
    font-weight: 400;
}